import React, { useState } from 'react'
import classes from './index.module.scss'
import Dropdown from 'react-dropdown';
import ChevronUp from '../../assets/ChevronUp.svg';
import ChevronDown from '../../assets/ChevronDown.svg';
import CalculatorBackground from '../../assets/CalculatorBackground.png';
import Chart from "react-apexcharts";
import Modal from 'react-modal';

const Calculator = () => {
    const [isOpen, setOpen] = useState(false)
    const options = [
        'FCR-D-INC', 'FCR-D-INC', 'FCR-D-INC'
    ];
    const currency = [
        'DKK', 'DKK', 'DKK'
    ];

    const chartOptions = {
        options: {
            chart: {
                id: "basic-bar"
            },
            colors: ["#1CD490"],
            xaxis: {
                categories: ["January", "February", "March", "April", "May", "June", "July", "August"],
                labels: {
                    style: {
                        colors: "#6A7682",
                        fontSize: '12px',
                        fontFamily: 'poppins',
                        fontWeight: 500,
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: "#6A7682",
                        fontSize: '12px',
                        fontFamily: 'poppins',
                        fontWeight: 500,
                    }
                }
            }
        },
        series: [
            {
                name: "series-1",
                data: [0, 0, 0, 4000000, 0, 0, 0]
            }
        ]
    };
    return (
        <div className={classes.Container}>
            <div className={classes.CalculatorContainer} style={isOpen ? { filter: "blur(10px)" } : {}}>
                <div className={classes.CalculatorHeader}>Calculator</div>
                <div className={classes.CalculatorInfo}>
                    You own an electric boiler with the capacity of 1MW in DK2. The electric boiler can turn off production in 2 minutes for 1 hour (upregulation) and needs 1 hour to scale up to full capacity. Hence, you are able to deliver aFRR and mFRR in DK2. You expect a daily participation of 12 hours. The graph to the left, show the average price the latest whole month. The graph to the right show the potential earnings from sold capacity in the last 30 days. <br />NB! This is an indicative calculation on historical data (changing every month), showing the potential revenue and is not guarantee for future revenues. Furthermore, it does not take account for associated costs/expenses for participating in the markets.
                </div>
                <div className={classes.CalculatorWidget}>
                    <div className={classes.CalculatorWidgetHeader}>
                        <div className={classes.CalculatorWidgetHeaderContainer}>
                            <div className={classes.CalculatorDropdownLabel}>Select Market</div>
                            <Dropdown
                                className={classes.Dropdown}
                                controlClassName={classes.DropdownControl}
                                options={options}
                                onChange={(e) => console.log(e)}
                                value={options[0]}
                                placeholder="Select an option"
                                arrowClosed={<img src={ChevronDown} alt='arrow down' className={classes.DropdownArrow} />}
                                arrowOpen={<img src={ChevronUp} alt='arrow up' className={classes.DropdownArrow} />}
                            />
                        </div>
                        <div className={classes.CalculatorWidgetHeaderContainer}>
                            <div className={classes.CalculatorDropdownLabel}>Capacity</div>
                            <input type='text' className={classes.DropdownControlInput} />
                        </div>
                        <div className={classes.CalculatorWidgetHeaderContainer}>
                            <div className={classes.CalculatorDropdownLabel}>Select Currency</div>
                            <Dropdown
                                className={classes.DropdownCurrency}
                                controlClassName={classes.DropdownControl}
                                options={currency}
                                onChange={(e) => console.log(e)}
                                value={currency[0]}
                                placeholder="Select an option"
                                arrowClosed={<img src={ChevronUp} alt='arrow down' className={classes.DropdownArrow} />}
                                arrowOpen={<img src={ChevronDown} alt='arrow up' className={classes.DropdownArrow} />}
                            />
                        </div>
                        <div className={classes.CalculatorWidgetHeaderContainer}>
                            <div className={classes.CalculatorDropdownLabel}>Start Date</div>
                            <input type='text' className={classes.DropdownControlInput} />
                        </div>
                        <div className={classes.CalculatorWidgetHeaderContainer}>
                            <div className={classes.CalculatorDropdownLabel}>End Date</div>
                            <input type='text' className={classes.DropdownControlInput} />
                        </div>
                        <div className={classes.SubmitButton} onClick={() => setOpen(true)}>Submit</div>
                    </div>
                    <div className={classes.CalculatorChart}>
                        <div className={classes.ChartDisplayContainer}>
                            <div className={classes.ChartDisplayData}>
                                Revenue In DDK : <span className={classes.ChartDisplayDataNumber} >68,738.93</span>
                            </div>
                            <div className={classes.ChartDisplayData}>
                                Revenue In Euro : <span className={classes.ChartDisplayDataNumber} >68,738.93</span>
                            </div>
                        </div>
                        <div className={classes.ChartContainer}>
                            <Chart
                                options={chartOptions.options}
                                series={chartOptions.series}
                                type="bar"
                                width="672"
                                height="186" />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setOpen(false)}
                className={classes.ModalClass}
                contentLabel="Example Modal"
                overlayClassName={classes.CalculatorOverlay}
            >
                <div className={classes.ModalHeader}>Enter your Email ID to view the results</div>
                <input type="email" className={classes.InputField} name="email" placeholder='Email' required />
                <button type="submit" className={classes.SubmitForm} onClick={() => setOpen(false)}>Submit</button>
            </Modal>
        </div>
    )
}

export default Calculator