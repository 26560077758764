import React, { useState } from 'react';
import classes from './index.module.scss';
import Logo from '../../assets/Logo.svg';
import Hamburger from '../../assets/Hamburger.svg';
import { HashLink } from 'react-router-hash-link';

const Header = ({ isScrolled }) => {
    const [activeLink, setActiveLink] = useState(1);
    const [hamburgerOptions, setHamburgerOptions] = useState(false);
    const HEADER_LINKS = [
        {
            id: 1,
            sectionId: "home",
            path: '/',
            text: 'Home'
        },
        {
            id: 2,
            sectionId: "features",
            path: '/',
            text: 'Features'
        },
        {
            id: 3,
            sectionId: "truegreen",
            path: '/',
            text: 'TrueGreen'
        },
        {
            id: 4,
            path: '/',
            sectionId: "truegreenWay",
            text: 'TrueGreen Way'
        },
        {
            id: 5,
            path: 'https://scnordicshop.dk/en/',
            external: true,
            text: 'Shop'
        },
        {
            id: 6,
            path: '/',
            sectionId: "contactUs",
            text: 'Contact Us'
        }
    ]
    return (
        <div className={`${classes.HeaderContainer} ${isScrolled && classes.HeaderContainerScrolled}`} id='home'>
            <div className={classes.LogoContaier}>
                <img className={classes.Logo} src={Logo} alt='logo' />
            </div>
            <div className={classes.HeaderLinks}>
                {HEADER_LINKS.map(link => (
                    <>
                        {
                            link.external ? <a
                                key={link.id}
                                className={`${classes.HeaderLink}`}
                                href={link.path}
                                target='_blank'

                            >
                                {link.text}
                            </a> :
                                <HashLink smooth to={`/#${link.sectionId}`} className={classes.HeaderLinkAnchor} >
                                    <div
                                        key={link.id}
                                        className={`${classes.HeaderLink} ${activeLink === link.id ? classes.selected : ""}`}
                                        onClick={() => setActiveLink(link.id)}
                                    >
                                        {link.text}
                                    </div>
                                </HashLink>
                        }
                    </>
                ))}
            </div>
            <img className={classes.Hamburger} src={Hamburger} onClick={() => setHamburgerOptions(!hamburgerOptions)} alt='hamburger' />
            <a className={classes.btnContainer} href='https://truegreen.scnordic.com/' target='_blank' rel="noreferrer"><div className={classes.btn}>Login</div></a>
            {hamburgerOptions ? <div className={classes.HamburgerOptions}>
                {HEADER_LINKS.map(link => (
                    <>
                        {
                            link.external ? <a
                                key={link.id}
                                className={`${classes.HeaderLink}`}
                                href={link.path}
                                target='_blank'

                            >
                                {link.text}
                            </a> :
                                <HashLink smooth to={`/#${link.sectionId}`} onClick={() => setHamburgerOptions(false)} className={classes.HeaderLinkAnchor} >
                                    <div
                                        key={link.id}
                                        className={`${classes.HeaderLink} ${activeLink === link.id ? classes.selected : ""}`}
                                        onClick={() => setActiveLink(link.id)}
                                    >
                                        {link.text}
                                    </div>
                                </HashLink>
                        }
                    </>
                ))}
            </div> : <></>}

        </div>
    )
}

export default Header