import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../views/Home';
import Calculator from '../views/Calculator';
import PrivacyPolicy from '../views/PrivacyPolicy';

const AppRoute = () => {

    return (
        <Routes>
            <Route path='/' exact element={<Home />} />
            <Route path='/privacy-policy' exact element={<PrivacyPolicy />} />
            <Route path='/calculator' exact element={<Calculator />} />
        </Routes>
    );
};

export default AppRoute;
