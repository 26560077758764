import React from "react";
import classes from "./index.module.scss";
import DemandResponse from "../../assets/DemandResponse.svg";
import DatahubIntegration from "../../assets/DatahubIntegration.svg";
import Edge from "../../assets/Edge.svg";
import PowerExchangeIntegration from "../../assets/PowerExchangeIntegration.svg";
import RealTimeMonitoring from "../../assets/RealTimeMonitoring.svg";
import LoadShifting from "../../assets/LoadShifting.svg";
import GridBalancing from "../../assets/GridBalancing.svg";
import Onboarding from "../../assets/Onboarding.svg";
import Shield from "../../assets/Shield.svg";
import Ecosystem from "../../assets/Ecosystem.svg";
import Application from "../../assets/Application.svg";
import TgEdge from "../../assets/TgEdge.svg";
import TgMobile from "../../assets/TgMobile.svg";
import Reveal from "../../Utils/Reveal";

const Features = () => {
  const FEATURES = [
    {
      id: 1,
      image: DemandResponse,
      header: "Demand Response",
      subText: "Allow your assets into your grid's demand response",
    },
    {
      id: 2,
      image: RealTimeMonitoring,
      header: "Real Time Monitoring",
      subText: "Track the real time status of all your assets",
    },
    {
      id: 3,
      image: Edge,
      header: "Edge",
      subText: "All the I/O is powered by edge OS.",
    },
    {
      id: 4,
      image: LoadShifting,
      header: "Load Shifting",
      subText: "Schedule your electricity usage based on the hourly prices",
    },
    {
      id: 5,
      image: PowerExchangeIntegration,
      header: "Power Exchange Integration",
      subText: "Track power prices and trading statistics",
    },
    {
      id: 6,
      image: DatahubIntegration,
      header: "Datahub Integration",
      subText: "Integrate your datahub account and track utility usage",
    },
  ];
  return (
    <div className={classes.FeatureContainer} id="features">
      <Reveal>
        <div className={classes.FeatureContainerHeader}>Features</div>
        <hr className={classes.FeatureContainerHeaderBorder} />
      </Reveal>

      <div className={classes.FeatureListContainer}>
        {FEATURES.map((feature) => (
          <Reveal>
            <div className={classes.FeatureCard} key={feature.id}>
              <img
                className={classes.FeatureCardImage}
                src={feature.image}
                alt="feature rep"
              />
              <div className={classes.FeatureCardHeader}>{feature.header}</div>
              <div className={classes.FeatureCardSubText}>
                {feature.subText}
              </div>
              {/* <img
                src={FeatureArrow}
                className={classes.FeatureCardLink}
                alt="feature rep"
              /> */}
            </div>
          </Reveal>
        ))}
      </div>

      <div className={classes.AdditionalFeatures}>
        <div className={classes.AdditionalFeaturesDesc}>
          <Reveal>
            <div className={classes.HeroText}>
              <div className={classes.HeroTextHeader}>
                Grid Balancing
                <br />
              </div>
              <span style={{ color: "#fff" }}>Like Never Before</span>

              <div className={classes.HeroTextInfo}>
                S.C.Nordic is a balance service provider, managing electricity
                usage to ease grid strain and providing revenue opportunities.
              </div>
            </div>
          </Reveal>
        </div>
        <div className={classes.AdditionalFeaturesDesc}>
          <Reveal>
            <img
              src={GridBalancing}
              alt="Grid Balancing"
              className={classes.GridBalancing}
            />
          </Reveal>
        </div>
      </div>
      <Reveal>
        <div className={classes.AdditionalCustomerFeatures}>
          <div className={classes.AdditionalCustomerFeature}>
            <img
              src={Onboarding}
              className={classes.AdditionalCustomerFeatureImage}
              alt="Onboarding"
            />

            <div className={classes.AdditionalFeaturesText}>
              <div className={classes.AdditionalCustomerFeatureTextHeader}>
                Effortless onboarding
              </div>
              <div className={classes.AdditionalCustomerFeatureTextInfo}>
                S.C. Nordic manages everything end to end, from system setup to
                TSO approvals
              </div>
            </div>
          </div>
          <div className={classes.AdditionalCustomerFeature}>
            <img
              src={Shield}
              className={classes.AdditionalCustomerFeatureImage}
              alt="Onboarding"
            />
            <div className={classes.AdditionalFeaturesText}>
              <div className={classes.AdditionalCustomerFeatureTextHeader}>
                No Upfront Costs
              </div>
              <div className={classes.AdditionalCustomerFeatureTextInfo}>
                We charge no fees until you actually make revenue out of your
                assets
              </div>
            </div>
          </div>
        </div>
      </Reveal>
      <Reveal>
        <div className={classes.AdditionalFeatures2} id="truegreen">
          <img src={Ecosystem} alt="Ecosystem" className={classes.Ecosystem} />
          <div
            className={classes.HeroText}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <span style={{ color: "#fff" }}>The</span>
              <span className={classes.HeroTextHeader}> TrueGreen </span>
              <span style={{ color: "#fff" }}>Ecosystem</span>
            </div>
            <div
              className={classes.HeroTextInfo}
              style={{ width: "35vw", textAlign: "center" }}
            >
              TrueGreen ecosystem consists of everything you need to succeed in
              the regulation market
            </div>
          </div>
        </div>
      </Reveal>
      <Reveal>
        <div className={classes.Applications}>
          <div className={classes.Application}>
            <img
              src={Application}
              className={classes.ApplicationImage1}
              alt="Onboarding"
            />
            <div className={classes.ApplicationText}>
              <div className={classes.ApplicationTextHeader}>
                TG Application
              </div>
              <div className={classes.ApplicationTextInfo}>
                The Trugreen web applicaton provides control and monitoring interface
              </div>
            </div>
          </div>
          <div className={classes.Application}>
            <img
              src={TgEdge}
              className={classes.ApplicationImage2}
              alt="Onboarding"
            />
            <div className={classes.ApplicationText}>
              <div className={classes.ApplicationTextHeader}>TG Edge</div>
              <div className={classes.ApplicationTextInfo}>
                The logging and controlling is powered by our edge system that
                runs locally on the asset premise.
              </div>
            </div>
          </div>
          <div className={classes.Application}>
            <img
              src={TgMobile}
              className={classes.ApplicationImage3}
              alt="Onboarding"
            />
            <div className={classes.ApplicationText}>
              <div className={classes.ApplicationTextHeader}>TG Mobile App</div>
              <div className={classes.ApplicationTextInfo}>
                Access everything you need on our mobile app
              </div>
            </div>
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default Features;
