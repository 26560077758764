import React from "react";
import classes from "./index.module.scss";

const Hero = () => {
  return (
    <div className={classes.Hero}>
      <div>
        <h1 className={classes.HeroText}>PRIVACY POLICY</h1>
        {/* <p className={classes.HeroTextInfo}>Effective Date: [Insert Date]</p> */}
      </div>
    </div>
  );
};

export default Hero;
