import React from "react";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { RECAPTCHA_SITE_KEY } from "../../config";

const ReCaptcha = React.memo(({ setToken, refreshReCaptcha }) => {
  return (
    <div>
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
        <GoogleReCaptcha
          onVerify={setToken}
          refreshReCaptcha={refreshReCaptcha}
        />
      </GoogleReCaptchaProvider>
    </div>
  );
});

export default ReCaptcha;
