import React, { useState } from "react";
import classes from "./index.module.scss";
import ContactUs from "../../assets/ContactUs.webp";
import ContactUsMobile from "../../assets/ContactUsMobile.svg";
import FooterWave from "../../assets/FooterWave.svg";
import Location from "../../assets/Location.svg";
import Phone from "../../assets/Phone.svg";
import Logo from "../../assets/Logo.svg";
import PhoneIcon from "../../assets/AddPhone.svg";
import RibbonIcon from "../../assets/Ribbon.svg";
import Reveal from "../../Utils/Reveal";
import { Field, Form, Formik } from "formik";
import {
  ContactUsValidation,
  NewsletterValidation,
} from "../../validations/homeValidations";
import { HomeService } from "../../services/homeService";
import { HashLink } from "react-router-hash-link";
import ReCaptcha from "../ReCaptcha";

const Footer = React.memo(() => {
  const HEADER_LINKS = [
    {
      id: 1,
      sectionId: "home",
      path: "/",
      text: "Home",
    },
    {
      id: 2,
      sectionId: "features",
      path: "/",
      text: "Features",
    },
    {
      id: 3,
      sectionId: "truegreen",
      path: "/",
      text: "TrueGreen",
    },
    {
      id: 4,
      path: "/",
      sectionId: "truegreenWay",
      text: "TrueGreen Way",
    },
    {
      id: 5,
      path: "https://scnordicshop.dk/en/",
      external: true,
      text: "Shop",
    },
    {
      id: 6,
      path: "/",
      sectionId: "contactUs",
      text: "Contact Us",
    },
  ];

  const ADDRESSES = [
    {
      country: "Denmark",
      address: "Hjarbækvej 65, Port 21, DK-8831 Løgstrup, CVR: DK41 71 86 84",
      phone: "+45 93 89 93 36",
    },
    {
      country: "US",
      address: "299 CALIFORNIA AVENUE, SUITE 200 PALO ALTO / CA 94306",
      phone: "+1 (650) 543 8174",
    },
    {
      country: "India",
      address:
        "HD-400, WeWork Berger Delhi One Sector 16B, C-001/A2, Noida - 201301",
      phone: "+91-9810111789",
    },
  ];

  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  // const [tokenVerified, setTokenVerified] = useState(false);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error1, setError1] = useState(null);
  const [success1, setSuccess1] = useState(null);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       if (token) {
  //         await HomeService.VerifyToken(token);
  //         setTokenVerified(true);
  //       }
  //     } catch (error) {
  //       setTokenVerified(false);
  //     }
  //   })();
  // }, [token]);

  const handleSubmit = async (values) => {
    try {
      setSuccess(null);
      if (token) {
        try {
          setError(null);
          await HomeService.SendLead(values, token);
          setSuccess("Message Received! We will get in touch soon.");
        } catch (error) {
          setError(error?.response?.data?.message);
        }
      } else setError("Complete Verification");
    } catch (error) {}
    setRefreshReCaptcha((r) => !r);
  };

  const handleSubmitNewsletter = async (values) => {
    try {
      setSuccess1(null);
      if (token) {
        try {
          setError1(null);
          await HomeService.SubNewsletter(values, token);
          setSuccess1("Subscription successful!");
        } catch (error) {
          setError1(error?.response?.data?.message);
        }
      } else setError1("Complete Verification");
    } catch (error) {}
    setRefreshReCaptcha((r) => !r);
  };

  return (
    <div className={classes.ContactUsContainer}>
      <Reveal>
        <div className={classes.ContactUs} id="contactUs">
          <img
            src={ContactUs}
            alt="contact us"
            className={classes.ContactUsImage}
          />
          <img
            src={ContactUsMobile}
            alt="contact us"
            className={classes.ContactUsMobile}
          />
          <div className={classes.ContactUsHeader}>Contact Us</div>
          <Reveal>
            <div className={classes.ContactUsForm}>
              <div className={classes.ContactUsFormChild1}>
                {ADDRESSES.map((add) => (
                  <div className={classes.ContactUsAddress}>
                    <img
                      src={RibbonIcon}
                      alt="ribbon"
                      className={classes.AddressRibbon}
                    />
                    <div className={classes.AddressHeader}>{add.country}</div>
                    <div className={classes.AddressSubHeader}>
                      <div>
                        <img
                          src={Location}
                          alt="contact us"
                          className={classes.AddressIcon}
                        />
                      </div>
                      {add.address}
                    </div>
                    <div className={classes.AddressSubHeader}>
                      <div>
                        <img
                          src={PhoneIcon}
                          alt="contact us"
                          className={classes.AddressIcon}
                        />
                      </div>
                      {add.phone}
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <div className={classes.ContactUsFormSubHeader}>
                  Please feel free to contact us if you need further assistance
                </div>
                <ReCaptcha
                  setToken={setToken}
                  refreshReCaptcha={refreshReCaptcha}
                />
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    message: "",
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={ContactUsValidation}
                >
                  {({ errors }) => (
                    <Form className={classes.Form}>
                      <div>
                        <Field
                          type="text"
                          name="name"
                          placeholder="Your Name"
                          className={`${classes.InputField} ${
                            errors.name && classes.InputFieldError
                          }`}
                        />
                      </div>
                      <div>
                        <Field
                          type="text"
                          name="email"
                          placeholder="youremail@example.com"
                          className={`${classes.InputField} ${
                            errors.email && classes.InputFieldError
                          }`}
                        />
                      </div>
                      <div>
                        <Field
                          type="text"
                          name="message"
                          className={`${classes.InputAreaField} ${
                            errors.message && classes.InputFieldError
                          }`}
                          placeholder="Your message"
                          component="textarea"
                        />
                      </div>
                      <div>
                        <button
                          type="submit"
                          className={classes.SubmitForm}
                          disabled={!token}
                        >
                          Send Message
                        </button>
                      </div>
                      {error && <div className={classes.Error}>{error}</div>}
                      {success && (
                        <div className={classes.Success}>{success}</div>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Reveal>
        </div>
      </Reveal>

      <div
        className={classes.Footer}
        style={{ backgroundImage: "url(" + FooterWave + ")" }}
      >
        <Reveal>
          <div className={classes.FooterContents}>
            <div className={classes.FooterContentsContact}>
              <img
                className={classes.FooterContentsContactLogo}
                src={Logo}
                alt="logo"
              />
              <div className={classes.FooterContentsContactAddressDetails}>
                <img
                  className={classes.FooterContentsContactAddressLogo}
                  src={Location}
                  alt="Location"
                />
                <div className={classes.FooterContentsContactAddress}>
                  {ADDRESSES[0].address}
                </div>
              </div>
              <div
                className={classes.FooterContentsContactAddressDetails}
                style={{ marginBottom: "1.5vw" }}
              >
                <img
                  className={classes.FooterContentsContactAddressLogo}
                  src={Phone}
                  alt="Location"
                />
                <div className={classes.FooterContentsContactAddress}>
                  {ADDRESSES[0].phone}
                </div>
              </div>
              <div className={classes.FooterContentsContactAddressDetails}>
                <img
                  className={classes.FooterContentsContactAddressLogo}
                  src={Location}
                  alt="Location"
                />
                <div className={classes.FooterContentsContactAddress}>
                  {ADDRESSES[1].address}
                </div>
              </div>
              <div
                className={classes.FooterContentsContactAddressDetails}
                style={{ marginBottom: "1.5vw" }}
              >
                <img
                  className={classes.FooterContentsContactAddressLogo}
                  src={Phone}
                  alt="Location"
                />
                <div className={classes.FooterContentsContactAddress}>
                  {ADDRESSES[1].phone}
                </div>
              </div>
              <div className={classes.FooterContentsContactAddressDetails}>
                <img
                  className={classes.FooterContentsContactAddressLogo}
                  src={Location}
                  alt="Location"
                />
                <div className={classes.FooterContentsContactAddress}>
                  {ADDRESSES[2].address}
                </div>
              </div>
              <div
                className={classes.FooterContentsContactAddressDetails}
                style={{ marginBottom: "1.5vw" }}
              >
                <img
                  className={classes.FooterContentsContactAddressLogo}
                  src={Phone}
                  alt="Location"
                />
                <div className={classes.FooterContentsContactAddress}>
                  {ADDRESSES[2].phone}
                </div>
              </div>
            </div>
            <div className={classes.FooterContentsLinks}>
              <div className={classes.FooterContentsLinksHeader}>Links</div>
              <div className={classes.FooterContentsLinksContainer}>
                {HEADER_LINKS.map((link) => (
                  <>
                    {link.external ? (
                      <a
                        key={link.id}
                        className={`${classes.FooterContentsLink}`}
                        href={link.path}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {link.text}
                      </a>
                    ) : (
                      <HashLink
                        smooth
                        className={classes.FooterContentsLink}
                        id={link.id}
                        to={`/#${link.sectionId}`}
                      >
                        {link.text}
                      </HashLink>
                    )}
                  </>
                ))}
                <a
                  key={"pp"}
                  className={`${classes.FooterContentsLink}`}
                  href="/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
            <div className={classes.FooterContentsNewsletter}>
              <div
                className={classes.FooterContentsLinksHeader}
                style={{ marginBottom: 0 }}
              >
                Subscribe to our newsletter
              </div>
              <div className={classes.FooterContentsNewsletterSubHeader}>
                Join our newsletter to receive updates and information about
                S.C.Nordic
              </div>
              <Formik
                initialValues={{
                  email: "",
                }}
                onSubmit={handleSubmitNewsletter}
                validationSchema={NewsletterValidation}
              >
                {({ errors }) => (
                  <Form className={classes.FooterContentsNewsletterForm}>
                    <Field
                      type="text"
                      name="email"
                      placeholder="Your email address"
                      className={`${classes.FooterContentsNewsletterInput} ${
                        errors.email && classes.InputFieldError
                      }`}
                    />
                    <button
                      type="submit"
                      className={classes.FooterContentsNewsletterSubmitForm}
                      disabled={!token}
                    >
                      Subscribe
                    </button>
                  </Form>
                )}
              </Formik>
              <div className={classes.FooterDetails}>
                {error1 && <div className={classes.Error}>{error1}</div>}
                {success1 && <div className={classes.Success}>{success1}</div>}
              </div>
            </div>
          </div>
        </Reveal>

        {/* <img
          src={FooterWave}
          className={classes.FooterImage}
          alt="footer wave"
        /> */}
      </div>
    </div>
  );
});

export default Footer;
