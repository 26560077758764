import React from "react";
import classes from "./index.module.scss";

const Description = () => {
  return (
    <div className={classes.Main}>
      <section className={classes.SubTitle}>
        This Privacy Policy governs the manner in which S.C. Nordic A/S, a
        company registered in Denmark, collects, uses, maintains, and discloses
        information collected from users ("you", "your") of the TrueGreen mobile
        and web applications (collectively referred to as "TrueGreen"). By using
        TrueGreen, you acknowledge and agree to the collection and use of
        information in accordance with this policy.
      </section>
      <section>
        <h2 className={classes.Title}>Data Controller</h2>
        <p className={classes.SubTitle}>
          S.C. Nordic A/S, registered in Denmark, is the Data Controller for any
          personal data processed via the TrueGreen platform. We are committed
          to protecting your privacy and complying with applicable data
          protection laws, including the General Data Protection Regulation
          (GDPR) and other relevant Danish regulations.
        </p>
      </section>
      <section>
        <h2 className={classes.Title}>Information We Collect</h2>
        <p className={classes.SubTitle}>
          We collect and process the following categories of data:
        </p>
        <ul>
          <li className={classes.SubTitle}>
            Personal Data:
            <ul style={{ listStyleType: "disc" }}>
              <li className={classes.SubTitle2}>Email address</li>
              <li className={classes.SubTitle2}>Phone number</li>
              <li className={classes.SubTitle2}>
                Password (stored in a hashed format to ensure security)
              </li>
            </ul>
          </li>
          <li className={classes.SubTitle}>
            IoT Data:
            <ul style={{ listStyleType: "disc" }}>
              <li className={classes.SubTitle2}>
                Frequency readings from connected meters
              </li>
              <li className={classes.SubTitle2}>
                Power consumption data from devices utilized for grid balancing
              </li>
              <li className={classes.SubTitle2}>
                Communication data between TrueGreen servers and customer
                devices
              </li>
            </ul>
          </li>
          <li className={classes.SubTitle}>
            Revenue Data: Data regarding the revenue generated from services
            provided to you.
          </li>
          <li className={classes.SubTitle}>
            Records related to the activation and operation of customer devices
            for diagnostic and service improvement purposes.
          </li>
        </ul>
      </section>
      <section>
        <h2 className={classes.Title}>Purpose of Data Processing </h2>
        <p className={classes.SubTitle}>
          The data we collect is processed for the following legitimate business
          purposes:
        </p>
        <ul>
          <li className={classes.SubTitle}>
            Account Management & Communication: Personal data (email, phone, and
            password) is collected to manage user accounts, ensure secure
            authentication, and facilitate communication between S.C. Nordic A/S
            and the customer.
          </li>
          <li className={classes.SubTitle}>
            Service Provision & Transparency: We process IoT data (frequency
            readings, power consumption) to display real-time operational
            information to our customers, enabling them to monitor their
            equipment performance.
          </li>
          <li className={classes.SubTitle}>
            Revenue Transparency: Revenue data is collected and stored to
            provide accurate information on the financial performance of your
            usage under our grid balancing services.
          </li>
          <li className={classes.SubTitle}>
            Diagnostics & System Improvement: Activation data is retained to
            track and improve the functioning of devices and to address system
            errors.
          </li>
        </ul>
      </section>
      <section>
        <h2 className={classes.Title}>Data Retention</h2>
        <p className={classes.SubTitle}>
          We retain personal and operational data as follows:
        </p>
        <ul>
          <li className={classes.SubTitle}>
            Personal Data (Email, Phone, Password): Retained for the duration of
            the customer's relationship with S.C. Nordic A/S.
          </li>
          <li className={classes.SubTitle}>
            IoT Data (Frequency, Power Consumption): Retained for a maximum of
            20 days, after which it is automatically deleted.
          </li>
          <li className={classes.SubTitle}>
            Activation Data: Retained for the lifetime of the customer
            relationship to support system improvement and diagnostic functions.
          </li>
          <li className={classes.SubTitle}>
            Revenue Data: Retained indefinitely or for as long as necessary to
            ensure transparency and proper business operations.
          </li>
        </ul>
      </section>
      <section>
        <h2 className={classes.Title}>Data Security Measures</h2>
        <p className={classes.SubTitle}>
          We have implemented industry-standard security measures to protect the
          confidentiality, integrity, and availability of your data. These
          measures include, but are not limited to:
        </p>
        <ul>
          <li className={classes.SubTitle}>
            Encryption: Passwords are securely stored using cryptographic
            hashing methods.
          </li>
          <li className={classes.SubTitle}>
            Restricted Access: Only authorized personnel have access to
            sensitive data, and such access is monitored and controlled.
          </li>
          <li className={classes.SubTitle}>
            Data Sharing: We do not share any customer or IoT data with third
            parties except for the following limited purposes:
            <ul style={{ listStyleType: "disc" }}>
              <li className={classes.SubTitle2}>
                Email communication: We utilize SendGrid to send emails, which
                requires the sharing of your email address.
              </li>
              <li className={classes.SubTitle2}>
                SMS communication: We utilize Nexmo to send SMS messages, which
                requires the sharing of your phone number.
              </li>
            </ul>
          </li>
        </ul>
        <p className={classes.SubTitle}>
          Beyond these specific cases, we do not disclose your data to third
          parties unless required by law or necessary to protect our legal
          interests.
        </p>
      </section>
      <section>
        <h2 className={classes.Title}>User Rights</h2>
        <p className={classes.SubTitle}>
          As a user, you have the following rights under applicable data
          protection law:
        </p>
        <ul>
          <li className={classes.SubTitle}>
            Right of Access: You have the right to request access to the
            personal data we hold about you.
          </li>
          <li className={classes.SubTitle}>
            Right to Rectification: You may request the correction of inaccurate
            or incomplete personal data.
          </li>
          <li className={classes.SubTitle}>
            Right to Erasure: You may request the deletion of your personal
            data, subject to our legal obligations and legitimate interests.
          </li>
          <li className={classes.SubTitle}>
            Right to Data Portability: You may request a copy of your personal
            data in a structured, commonly used, and machine-readable format.
          </li>
          <li className={classes.SubTitle}>
            Right to Object or Restrict Processing: You may object to the
            processing of your data or request that such processing be
            restricted in certain circumstances.
          </li>
        </ul>
        <p className={classes.SubTitle}>
          Please note that requests related to the deletion of IoT data must be
          submitted to us, as this data cannot be directly managed by users due
          to its nature and volume.
        </p>
      </section>
      <section>
        <h2 className={classes.Title}>Children's Privacy</h2>
        <p className={classes.SubTitle}>
          We do not knowingly collect personal data from children without
          explicit customer request. We do not store information related to
          users' age or date of birth. Accounts for minors may only be created
          at the explicit request of a customer, and we rely on the customer to
          ensure that all relevant consents have been obtained.
        </p>
      </section>
      <section>
        <h2 className={classes.Title}>Legal Basis for Processing</h2>
        <p className={classes.SubTitle}>
          The legal basis for our processing of personal data includes:
        </p>
        <ul>
          <li className={classes.SubTitle}>
            Contractual necessity: To provide services to customers in
            accordance with agreed-upon terms.
          </li>
          <li className={classes.SubTitle}>
            Legitimate interests: To ensure the functionality, security, and
            improvement of the TrueGreen services.
          </li>
          <li className={classes.SubTitle}>
            Compliance with legal obligations: To meet our obligations under
            Danish law and GDPR.
          </li>
        </ul>
      </section>
      <section>
        <h2 className={classes.Title}>International Data Transfers</h2>
        <p className={classes.SubTitle}>
          As a company based in Denmark, we store and process data within the
          European Union (EU). In the event that data is transferred outside of
          the EU, we will ensure adequate safeguards are in place to protect
          your privacy, in compliance with applicable data protection laws.
        </p>
      </section>
      <section>
        <h2 className={classes.Title}>Changes to This Privacy Policy</h2>
        <p className={classes.SubTitle}>
          S.C. Nordic A/S reserves the right to update this Privacy Policy at
          any time. Any amendments will be posted on this page, and we will
          notify you of any significant changes via email or through the
          TrueGreen application. Your continued use of the platform following
          any updates to this policy constitutes your acceptance of the revised
          terms.
        </p>
      </section>
      <section>
        <h2 className={classes.Title}>Contact Information</h2>
        <p className={classes.SubTitle}>
          If you have any questions about this Privacy Policy or wish to
          exercise your rights as outlined above, please contact us at:
        </p>
        <p className={classes.SubTitle}>S.C. Nordic A/S</p>
        <p>Hjarbækvej 65, Port 21, DK-8831 Løgstrup, CVR: DK41 71 86 84</p>
        <p>+45 93 89 93 36</p>
      </section>
    </div>
  );
};

export default Description;
