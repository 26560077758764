import React from "react";
import Counts from "../../assets/Numbers.svg";
import Steps from "../../assets/Steps.svg";
import StepsMobileImage from "../../assets/StepsMobileImage.svg";
import CTABackground from "../../assets/CTABackground.png";
import AppImages from "../../assets/AppImages.svg";
import AppStore from "../../assets/AppStore.svg";
import GooglePlay from "../../assets/GooglePlay.svg";
import WebApp from "../../assets/WebApp.svg";
import CEO from "../../assets/CEO.svg";
import CTADecor from "../../assets/CTADecor.svg";
import CeoLinkedin from "../../assets/CeoLinkedin.svg";
import classes from "./index.module.scss";
import Reveal from "../../Utils/Reveal";
import { HashLink } from "react-router-hash-link";

const CompanyDetails = () => {
  return (
    <div className={classes.CompanyDetails}>
      <Reveal>
        <div className={classes.Counters}>
          <img
            src={Counts}
            alt="counts"
            className={classes.CountersBackground}
          />
          <div className={classes.CounterText}>
            <div className={classes.CountersHeader}>Success In Numbers</div>
            <div className={classes.Counters}>
              <div className={classes.CounterNumber}>30+</div>
              <div className={classes.CounterHeader}>Customers</div>
            </div>
            <div className={classes.Counters}>
              <div className={classes.CounterNumber}>220+</div>
              <div className={classes.CounterHeader}>Total Sites</div>
            </div>
            <div className={classes.Counters}>
              <div className={classes.CounterNumber}>22 MW+</div>
              <div className={classes.CounterHeader}>Regulation Capacity</div>
            </div>
          </div>
        </div>
      </Reveal>
      <Reveal>
        <div className={classes.StepsContainer} id="truegreenWay">
          <div className={classes.StepsContainerHeader}>TrueGreen Way</div>
          <hr className={classes.StepsContainerBorder} />
          <div className={classes.StepsContainerInfo}>
            {/* The S.C. Nordic concept objective is to strengthen to potential for
            energy storage, by combining it with energy efficiency (EE) and
            Demand Response (DR) for consumers (DS) in an all-in-one package. */}
            The aim of the S.C. Nordic concept is to enhance energy storage by
            integrating it with energy efficiency and demand response for
            consumers into one complete package.
          </div>
          <Reveal>
            <img
              src={Steps}
              alt="steps"
              className={classes.StepsContainerImage}
            />
            <img
              src={StepsMobileImage}
              alt="steps"
              className={classes.StepsMobileImage}
            />

          </Reveal>
        </div>
      </Reveal>
      <Reveal>
        <div
          className={classes.CTA}
          style={{ backgroundImage: "url(" + CTABackground + ")" }}
        >
          {/* <img src={CTABackground} className={classes.CTAImage} alt='background' /> */}
          <div className={classes.CTAInfo}>
            <img src={CTADecor} className={classes.CTADecor} alt="background" />
            <div className={classes.CTAAction}>
              <div className={classes.CTAActionHeader}>
                Calculate your{" "}
                <span style={{ color: "#00E0BA" }}>revenue potential</span>
              </div>
              <HashLink
                className={classes.CTAActionButton}
                smooth
                to={"/#contactUs"}
              >
                Contact Us
              </HashLink>
            </div>
          </div>
        </div>
      </Reveal>
      <Reveal>
        {/* <hr className={classes.AppImagesUpperBorder} id='truegreen' /> */}
        <div className={classes.AppImagesContainer}>
          <div className={classes.AppImagesTextInfo}>
            <div className={classes.AppImagesHeader}>TrueGreen</div>
            <div className={classes.AppImagesSubHeader}>
              Smarter Consumers Turn Consumption Into Revenue
            </div>
            <div className={classes.AppImagesInfo}>
              Transform your assets into revenue with Truegreen apps available
              on all major platforms.
            </div>
            <div className={classes.AppImagesAppContainer}>
              <a
                href="https://truegreen.scnordic.com/"
                target="_blank"
                style={{ width: "100%", textAlign: "center" }}
                rel="noreferrer"
              >
                <img
                  className={classes.AppImagesApp}
                  src={WebApp}
                  alt="web app"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.mobile.sc.charge"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.AppImagesApp}
                  src={GooglePlay}
                  alt="google play"
                />
              </a>{" "}
              <a href="https://apps.apple.com/dk/app/truegreen/id1576832648" target="_blank" rel="noreferrer">
                <img
                  className={classes.AppImagesApp}
                  src={AppStore}
                  alt="app store"
                />
              </a>
            </div>
          </div>
          <img
            src={AppImages}
            className={classes.AppImages}
            alt="app screenshots"
          />
        </div>
        {/* <hr className={classes.AppImagesLowerBorder} /> */}
      </Reveal>
      <Reveal>
        <div className={classes.CeoContainer}>
          <div className={classes.StepsContainerHeader}>Meet Our CEO</div>
          <hr
            className={classes.StepsContainerBorder}
            style={{ marginBottom: "1.2vw" }}
          />
          <div className={classes.CeoContainerInfo}>
            <div className={classes.CeoContainerText}>
              <hr className={classes.CeoContainerUpperBorder} />
              <div className={classes.CeoQuotes}>
                “At S.C. Nordic, we're committed to sustainable living with
                innovative solutions. Our demand response programs help you
                balance the grid and earn revenue, making energy management
                smarter and more rewarding.”
              </div>
              <div className={classes.CeoPlatforms}>
                <a
                  href="https://www.linkedin.com/in/mogensbirkelund/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className={classes.CeoSocials}
                    src={CeoLinkedin}
                    alt="ceo"
                  />
                </a>

                {/* <img
                  className={classes.CeoSocials}
                  src={CeoFacebook}
                  alt="ceo"
                /> */}
              </div>
              <hr className={classes.CeoContainerLowerBorder} />
              {/* <div className={classes.CeoActionButton}>Explore Our Team</div> */}
            </div>
            <img className={classes.CeoImage} src={CEO} alt="ceo" />
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default CompanyDetails;
