import React from "react";
import classes from "./index.module.scss";
// import LeftThunder from '../../assets/LeftThunder.svg';
// import RightThunder from '../../assets/RightThunder.svg';
// import PlayButton from '../../assets/Play.svg';
import ClientLogo from "../../assets/ClientLogo.svg";
import HeroVideo from "../../assets/HeroVideo.mp4";
import Reveal from "../../Utils/Reveal";
import Marquee from "react-fast-marquee";
import { HashLink } from 'react-router-hash-link';

const Hero = () => {
  return (
    <>
      <div>
        <div className={classes.VideoContainer}>
          <video
            className={classes.BgVideo}
            src={HeroVideo}
            type="video/mp4"
            playsInline
            autoPlay
            muted
            loop="loop"
            preload="auto"
          />
          <div className={classes.HeroContent}>
            <Reveal>
              <div className={classes.HeroText}>
                <div className={classes.HeroTextHeader}>
                  Empower your assets
                  <br />
                  <span style={{ color: "#1CD490" }}>
                    Join the Grid Balance Revolution
                  </span>
                </div>
                <div className={classes.HeroTextInfo}>
                  S.C.Nordic offers innovative solutions for sustainable living,
                  including demand response programs, allowing users to balance
                  the grid and earn revenue.
                </div>
                <div className={classes.HeroTextButtonGroup}>
                  <HashLink smooth className={classes.GetStarted} to="#features">Get Started</HashLink>
                </div>
              </div>
            </Reveal>
          </div>
        </div>
        {/* <div className={classes.CompanyLogosParent}>
          <div className={classes.CompanyLogos}>
            <img
              src={ClientLogo}
              alt="client-logo"
              className={classes.ClientLogos}
            />
            <img
              src={ClientLogo}
              alt="client-logo"
              className={classes.ClientLogos}
            />
            <img
              src={ClientLogo}
              alt="client-logo"
              className={classes.ClientLogos}
            />
            <img
              src={ClientLogo}
              alt="client-logo"
              className={classes.ClientLogos}
            />
            <img
              src={ClientLogo}
              alt="client-logo"
              className={classes.ClientLogos}
            />
            <img
              src={ClientLogo}
              alt="client-logo"
              className={classes.ClientLogos}
            />
            <img
              src={ClientLogo}
              alt="client-logo"
              className={classes.ClientLogos}
            />
            <img
              src={ClientLogo}
              alt="client-logo"
              className={classes.ClientLogos}
            />
            <img
              src={ClientLogo}
              alt="client-logo"
              className={classes.ClientLogos}
            />
          </div>

          <div className={classes.CompanyLogosCarousel}>
            <Marquee className={classes.CompanyLogosCarouselComponent}>
              <img
                src={ClientLogo}
                alt="client-logo"
                className={classes.ClientLogos}
              />
              <img
                src={ClientLogo}
                alt="client-logo"
                className={classes.ClientLogos}
              />
              <img
                src={ClientLogo}
                alt="client-logo"
                className={classes.ClientLogos}
              />
              <img
                src={ClientLogo}
                alt="client-logo"
                className={classes.ClientLogos}
              />
              <img
                src={ClientLogo}
                alt="client-logo"
                className={classes.ClientLogos}
              />
              <img
                src={ClientLogo}
                alt="client-logo"
                className={classes.ClientLogos}
              />
              <img
                src={ClientLogo}
                alt="client-logo"
                className={classes.ClientLogos}
              />
              <img
                src={ClientLogo}
                alt="client-logo"
                className={classes.ClientLogos}
              />
              <img
                src={ClientLogo}
                alt="client-logo"
                className={classes.ClientLogos}
              />
            </Marquee>
          </div>
        </div> */}
      </div>
      {/* <div className={classes.HeroContainer}>
        <div className={classes.HeroContainerTop}>
          <div>
            <video
              className={classes.HeroContainerVideo}
              src={HeroVideo}
              type="video/mp4"
              playsInline
              autoPlay
              muted
              loop="loop"
            />
          </div>
          <Reveal>
            <div className={classes.HeroText}>
              <div className={classes.HeroTextHeader}>
                Empower your assets
                <br />
                <span style={{ color: "#1CD490" }}>
                  Join the Grid Balance Revolution
                </span>
              </div>
              <div className={classes.HeroTextInfo}>
                S.C.Nordic offers innovative solutions for sustainable living,
                including demand response programs, allowing users to balance
                the grid and earn revenue.
              </div>
              <div className={classes.HeroTextButtonGroup}>
                <div className={classes.GetStarted}>Get Started</div>
              </div>
            </div>
          </Reveal>
        </div>
      </div> */}
    </>
  );
};

export default Hero;
