import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Hero from "../../components/Hero";
import Features from "../../components/Features";
import CompanyDetails from "../../components/CompanyDetails";
import Footer from "../../components/Footer";

const Home = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const viewportHeight = window.innerHeight;

            if (scrollPosition > 0.2 * viewportHeight) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div id="home">
            <Header isScrolled={isScrolled} />
            <Hero />
            <Features />
            <CompanyDetails />
            <Footer />
        </div>
    );
};

export default Home;
